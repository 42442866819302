import HomePage from './components/HomePageClass'
import './App.css'

function App() {
  return (
    <div className="App">
        <HomePage />
    </div>
  );
}

export default App;

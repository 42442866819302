const MenuView = function(){
    return (

        <div>
            <h1> Menu </h1>
        </div>

    );
}

export default MenuView
